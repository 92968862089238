<template>
    <footer class="footer-section w-ba">
        <div class="container max-width-gib">
            <div class="row">
                <div class="provider-logo">
                    <img src="../assets/images/ag.png">
                    <img src="../assets/images/microgaming.png">
                    <img src="../assets/images/evolution.png">
                    <img src="../assets/images/pragmatic.png">
                    <img src="../assets/images/oriental.png">
                    <img class="no-shadow" src="../assets/images/sexygaming.png">
                    <img src="../assets/images/dreamgaming.png">
                    <img src="../assets/images/cq9.png">
                    <img src="../assets/images/bng.png">
                    <img src="../assets/images/habanero.png">
                    <img src="../assets/images/bgaming.png">
                    <img src="../assets/images/stargame.png">
                    <img src="../assets/images/evoplay.png">
                    <img src="../assets/images/aristocrat.png">
                    <img src="../assets/images/playngo.png">
                    <img src="../assets/images/genesis.png">
                </div>
            </div>
            <div class="copyright w-ba">
                <img src="../assets/images/logo-footer.png?v14">
                <p class="mb-0 mt-3">ⓒ COPYRIGHT VENTI CASINO ALL RIGHTS RESERVED.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer_city',
  data () {
    return {
    }
  }
}
</script>
<style>
</style>
