<template>
    <div class="wrapper-form wrapper-form_edit">
        <form method="post" @submit="cashIn">
            <table class="table-cashin">
                <tbody>
                    <tr>
                        <td colspan="3">
                            <label class="label_text">{{ langweb('fe.holdingamount')}}</label>
                            <div class="info-money">
                                <!-- <label>{{ langweb('fe.walletmoney')}}</label> -->
                                <input type="text" :value="amountinfo" class="moneyinfo">
                                <img src="../assets/images/reset.png" alt="" class="reset_money_info" @click="loadMoney">
                                <img src="../assets/images/ld.gif" alt="" class="loading_money">
                            </div>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td colspan="3">
                            <label class="label_text">{{ langweb('fe.accountnumber')}}</label>
                            <div class="title_numberbank">
                                <input type="text" :value="numberbank" class="moneyinfo">
                            </div>
                        </td>
                    </tr> -->
                    <tr>
                        <td colspan="3">
                            <label class="label_text">{{ langweb('fe.depositamount')}} (*)</label>
                            <div class="money-send">
                                <input type="number" placeholder="0" name="amount" v-model="amount" class="numbermoney">
                                <button type="button" id="money_1" class="btnmoney" @click="clvue" data_money="10000">{{ langweb('fe.10')}}</button>
                                <button type="button" id="money_2" class="btnmoney" @click="clvue" data_money="30000">{{ langweb('fe.30')}}</button>
                                <button type="button" id="money_3" class="btnmoney" @click="clvue" data_money="50000">{{ langweb('fe.50')}}</button>
                                <button type="button" id="money_4" class="btnmoney" @click="clvue" data_money="100000">{{ langweb('fe.100')}}</button>
                                <button type="button" id="money_5" class="btnmoney" @click="clvue" data_money="500000">{{ langweb('fe.500')}}</button>
                                <button type="button" id="money_6" class="btnmoney" @click="clvue" data_money="1000000">{{ langweb('fe.1000')}}</button>
                                <button type="button" id="money_7" class="btnmoney" @click="Resetfrm">{{ langweb('fe.resets')}}</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="btn-cashin">
                <button type="submit" class="btn-01" id="submit_btn">{{ langweb('fe.apply')}}</button>
                <form method="post" @submit="senChatCoin" class="form-replychat_cashin">
                    <select v-model="title" class="select-title_coin">
                        <option v-for="(titlelt, index) in listtitle.slice(0, 1)" v-bind:key="index" :value="titlelt.id">{{ titlelt.question }}</option>
                    </select>
                    <textarea v-for="(titlelts, indexs) in listtitle.slice(0, 1)" v-model="titlelts.question" v-bind:key="indexs" name="question_content" class="answer_content content-send-chat" cols="30" rows="6"></textarea>
                    <button type="submit" class="btn-02" id="submit_btn_chatv">{{ langweb('fe.coin')}}</button>
                    <!--  @click="clicksendCoin" -->
                </form>
            </div>
            <p class="text_bottom_button">{{ langweb('fe.textbottombutton')}}</p>
        </form>
        <!-- modal coin -->
        <!-- <div class="modal-vue modal-update-reply">
            <form method="post" @submit="senChatCoin" class="form-replychat">
                <div class="overlay" v-if="modalSend" @click="modalSend = false"></div>
                <div class="modal" v-if="modalSend">
                    <div class="wp-modal-reply">
                        <div class="header-modal">
                            <div class="title-header" style="color: #ffd989;">{{ langweb('fe.coin') }}</div>
                            <button class="close-modal" @click="modalSend = false">x</button>
                        </div>
                        <div class="content-modal">
                            <div class="content-repchat">
                                <select v-model="title" class="select-title_coin">
                                    <option v-for="(titlelt, index) in listtitle.slice(0, 1)" v-bind:key="index" :value="titlelt.id">{{ titlelt.question }}</option>
                                </select>
                                <textarea v-model="question_content" class="answer_content content-send-chat" cols="30" rows="6"></textarea>
                            </div>
                            <div class="btn-repchat">
                                <button type="submit" id="submit_btn_chat">{{ langweb('fe.send') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div> -->
        <!-- end modal coin -->
    </div>
</template>

<script>
import { useStore } from "vuex";
import {  computed } from 'vue';
import $ from 'jquery'
$( document ).ready(function() {
});
export default {
    name: 'Cashin_city',
    setup() {
        const store = useStore();
        const lang = computed(() => store.state.lang);
        const lang_en = computed(() => store.state.language);
        store.dispatch("changLang");
        return {
            langweb (variable) {
                lang.value.filter(function(e){
                    if(e.variable == variable) {
                        if(lang_en.value == 'en'){
                            variable = e.en
                        }else{
                            variable = e.kr
                        }
                    }
                });
                return variable;
            },
        }
    },
    data () {
        return {
            amountinfo: '',
            // numberbank: '',
            amount: 0,
            modalSend: false,
            id_user: '1',
            title: '',
            question_content: '',
            listtitle: []
        }
    },
    created () {
        this.getListInfo()
        // setInterval(this.getListInfo, 20000);
        this.listTitle()
    },
    components: {
    },
    methods: {
        cashIn (e) {
            e.preventDefault()
            var token_user = window.localStorage.getItem('token')
            this.amount = $('.numbermoney').val()
            $("#submit_btn").addClass('submit_btn');
            document.getElementById("submit_btn").disabled = true;
            this.axios.post(this.api_cashin, 
                {
                    amount: this.amount
                },
                { headers: {
                    Authorization: 'Bearer ' + token_user
                }},
            ).then(res => {
                if (res.data.success === "Success") {
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.amount = ''
                    $("#submit_btn").removeClass('submit_btn');
                    document.getElementById("submit_btn").disabled = false;
                } else {
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.amount = ''
                    $("#submit_btn").removeClass('submit_btn');
                    document.getElementById("submit_btn").disabled = false;
                }
            }).catch(e => {
                if (e.response.status === 404) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    $("#submit_btn").removeClass('submit_btn');
                    document.getElementById("submit_btn").disabled = false;
                }
            })
        },
        getListInfo () {
            var token_user = window.localStorage.getItem('token')
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + token_user
                }
            }).then((response) => {
                this.amountinfo = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(Number(response.data.data.user.amount) + Number(response.data.data.user.casino))
                // this.numberbank = response.data.data.user.number_bank
            })
        },
        clvue (event) {
            var datamn = Number(event.target.getAttribute('data_money'))
            var moneys = $('.numbermoney').val()
            var money = ''
            var money_total = ''
            if(moneys==''){
                money = 0
            }else{
                money = moneys
            }
            money_total = Number(datamn) + Number(money)
            $('.numbermoney').val(money_total)
        },
        clicksendCoin () {
            this.modalSend = true
        },
        listTitle () {
            var token_user = window.localStorage.getItem('token')
            this.axios.get(this.api_listtitle, {
                headers: {
                    Authorization: 'Bearer ' + token_user
                }
            }).then((response) => {
                this.listtitle = response.data.data.filter(d => d.type == 1)
                this.title = this.listtitle[0]?.id
            })
        },
        senChatCoin (e) {
            e.preventDefault()
            var token_user = window.localStorage.getItem('token')
            this.title = "계좌문의"//$('.select-title_coin').val()
            this.question_content = "입금계좌 요청드립니다."//$('.content-send-chat').val()
            $("#submit_btn_chatv").addClass('submit_btn');
            document.getElementById("submit_btn_chatv").disabled = true;
            this.axios.post(this.api_sendchat, 
                {
                    title: this.title,
                    question_content: this.question_content,
                    user_receive: this.id_user
                },
                { headers: {
                    Authorization: 'Bearer ' + token_user
                }},
            ).then(res => {
                if (res.data.success === "true") {
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.title = this.question_content = ''
                    this.modalSend = false
                    $("#submit_btn_chatv").removeClass('submit_btn');
                    document.getElementById("submit_btn_chatv").disabled = false;
                } else {
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.title = this.question_content = ''
                    this.modalSend = false
                    $("#submit_btn_chatv").removeClass('submit_btn');
                    document.getElementById("submit_btn_chatv").disabled = false;
                }
            }).catch(e => {
                if (e.response.status === 404) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    $("#submit_btn_chatv").removeClass('submit_btn');
                    document.getElementById("submit_btn_chatv").disabled = false;
                }
            })
        },
        Resetfrm () {
            $('.numbermoney').val('0')
        },
        loadMoney () {
            $('.loading_money').show()
            var token_user = window.localStorage.getItem('token')
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + token_user
                }
            }).then((response) => {
                $('.loading_money').css('display', 'none')
                this.amountinfo = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(Number(response.data.data.user.amount) + Number(response.data.data.user.casino))
            })
        }
    }
}
</script>
<style>
</style>
