<template>
    <div id="header_city8888">
        <section class="banner-section w-ba">
            <img class="banner-bg" src="../assets/images/banner-bg.png">
            <div class="container h-100 dflex-ac-jc">
               <div class="center logo-city8888">
                    <router-link class="logo" v-bind:to="{ name: 'Home_city' }">
                        <div class="logo88">                            
                            <img class="a" src="../assets/images/enti.png">
                        </div>
                        
                    </router-link>
                </div>
                <div class="banner-background w-ba">
                    <div class="glow-left">
                        <img src="../assets/images/glow-left.png">
                    </div>
                    <img class="casino-items" src="../assets/images/casino-items.png">
                    <img class="girl-right" src="../assets/images/girl-right.png">
                    <img class="girl-left" src="../assets/images/girl-left.png">
                    <div class="glow-right">
                        <img src="../assets/images/glow-right.png">
                    </div>
                    <img class="candy-slot" src="../assets/images/candy-slot.png">
                    <img class="money-slot" src="../assets/images/money-slot.png">
                </div>
            </div>
        </section>
        <!-- <section class="banner-section w-b">
            <div class="container container_logo max-width-gib dflex-ae-jc h-100">
                <div class="center_logo">
                    <router-link class="logo" v-bind:to="{ name: 'Home_city' }">
                        <img class="logo-img" src="../assets/images/logo-img.png">
                        <img class="t-bot" src="../assets/images/v.png">
                        <img class="itle" src="../assets/images/enti.png">
                        <img class="casino" src="../assets/images/casino.png">
                    </router-link>
                    <div class="item_sl">
                        <h2 class="font-bold">{{ langweb('fe.textslider') }}</h2>
                        <h1 class="font-bold text-yellow">{{ langweb('fe.textslider1') }}</h1>
                        <h1 class="font-bold text-yellow">{{ langweb('fe.textslider2') }}</h1>
                    </div>
                </div>
                <div class="banner-background">
                    <div class="golf-ball">
                        <img class="d-img golf-glow" src="../assets/images/golf-glow.png">
                        <img class="golf-img" src="../assets/images/golf-ball.png">
                    </div>
                    <img class="dealer-02" src="../assets/images/dealer-02.png">
                    <div class="dealer-01">
                        <img class="seven" src="../assets/images/seven.png">
                        <img class="d-img" src="../assets/images/dealer-01.png">
                    </div>
                    <img class="items" src="../assets/images/items.png">
                    <img class="casino-table" src="../assets/images/table.png">
                    <img class="dealer-04" src="../assets/images/dealer-04.png">
                    <div class="dealer-03">
                        <img class="casino-card" src="../assets/images/card.png">
                        <img class="club" src="../assets/images/club.png">
                        <img class="d-img" src="../assets/images/dealer-03.png">
                    </div>
                    <img class="chips" src="../assets/images/chips.png">
                </div>
            </div>
        </section> -->
        <header class="header-section w-ba header-city88">
            <div class="container dflex-ac-jc h-100 max-width-gib header-mb">
                <ul class="bs-ul main-menu sidebar-left">
                    <li>
                        <a class="dflex-ac-jc w-ba" @click="showLevel" v-if="level == 1 && offline == 1">
                            <font-awesome-icon icon="fa-solid fa-piggy-bank" />&nbsp; {{ langweb('fe.cashin') }}
                        </a>
                        <a class="dflex-ac-jc w-ba" data_id="1" @click="showMain" v-else-if="checktoken === true">
                            <font-awesome-icon icon="fa-solid fa-piggy-bank" />&nbsp; {{ langweb('fe.cashin') }}
                        </a>
                        <a class="dflex-ac-jc w-ba" v-else @click="showModal = true">
                            <font-awesome-icon icon="fa-solid fa-piggy-bank" />&nbsp; {{ langweb('fe.cashin') }}
                        </a>
                    </li>
                    <li>
                        <a class="dflex-ac-jc w-ba" @click="showLevel" v-if="level == 1 && offline == 1">
                            <font-awesome-icon icon="fa-solid fa-piggy-bank" />&nbsp; {{ langweb('fe.cashout') }}
                        </a>
                        <a class="dflex-ac-jc w-ba" data_id="2" @click="showMain" v-else-if="checktoken === true">
                            <font-awesome-icon icon="fa-solid fa-vault" />&nbsp; {{ langweb('fe.cashout') }}
                        </a>
                        <a class="dflex-ac-jc w-ba" v-else @click="showModal = true">
                            <font-awesome-icon icon="fa-solid fa-vault" />&nbsp; {{ langweb('fe.cashout') }}
                        </a>
                    </li>
                    <li v-if="checktoken === true" class="convert_desktop">
                        <a class="dflex-ac-jc w-ba" data_id="6" @click="showMain" v-if="checktoken === true">
                            <font-awesome-icon icon="fa-solid fa-money-bill-transfer" />&nbsp; {{ langweb('fe.pointtoamount') }}
                        </a>
                    </li>
                    <li>
                        <a class="dflex-ac-jc w-ba" data_id="3" @click="showMain" v-if="checktoken === true">
                            <font-awesome-icon icon="fa-solid fa-bell" />&nbsp; {{ langweb('fe.notification') }}
                            <sup class="number_info" v-if="notifi > 0">{{ notifi }}</sup>
                        </a>
                        <a class="dflex-ac-jc w-ba" v-else @click="showModal = true">
                            <font-awesome-icon icon="fa-solid fa-bell" />&nbsp; {{ langweb('fe.notification') }}
                        </a>
                    </li>
                    <li>
                        <a class="dflex-ac-jc w-ba" data_id="4" @click="showMain" v-if="checktoken === true">
                            <font-awesome-icon icon="fa-solid fa-comment-dots" />&nbsp; {{ langweb('fe.chat') }}
                            <sup class="number_info" v-if="chat > 0">{{ chat }}</sup>
                        </a>
                        <a class="dflex-ac-jc w-ba" v-else @click="showModal = true">
                            <font-awesome-icon icon="fa-solid fa-comment-dots" />&nbsp; {{ langweb('fe.chat') }}
                        </a>
                    </li>
                    <li>
                        <a class="dflex-ac-jc w-ba" data_id="11" @click="showMain" v-if="checktoken === true">
                            <font-awesome-icon icon="fa-solid fa-comments" />&nbsp; {{ langweb('fe.sends') }}
                            <sup class="number_info" v-if="send > 0">{{ send }}</sup>
                        </a>
                        <a class="dflex-ac-jc w-ba" v-else @click="showModal = true">
                            <font-awesome-icon icon="fa-solid fa-comments" />&nbsp; {{ langweb('fe.sends') }}
                        </a>
                    </li>
                    <!-- <li v-if="checktoken === true">
                        <router-link class="dflex-ac-jc w-ba" v-bind:to="{ name: 'Partner_city' }">
                            <font-awesome-icon icon="fa-solid fa-handshake" />&nbsp;{{ langweb('fe.partner') }}
                        </router-link>
                    </li> -->
                </ul>
                <div class="bal-container info-login-game" v-if="checktoken === true">
                    <div class="login-info">
                        <div class="list-info">
                            <ul>
                                <li>
                                    <div><font-awesome-icon icon="fa-solid fa-hand-holding-dollar" /></div>
                                    <div>
                                        <p>{{ langweb('fe.amount') }}</p>
                                        <p>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(total_mn) }}</p>
                                    </div>
                                </li>
                                <li>
                                    <div><font-awesome-icon icon="fa-solid fa-money-bill-1-wave" /></div>
                                    <div>
                                        <p>{{ langweb('fe.point') }}</p>
                                        <p>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(point) }}</p>
                                    </div>
                                </li>
                                <!-- <li>
                                    <div><font-awesome-icon icon="fa-solid fa-money-bill" /></div>
                                    <div>
                                        <p>{{ langweb('fe.bacara') }}</p>
                                        <p>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(casino) }}</p>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                        <div class="more-account">
                            <ul>
                                <li class="account_edit">
                                    <!-- <span class="account-name">{{ nameuser }} </span>
                                    <font-awesome-icon icon="fa-solid fa-circle-user" /> -->
                                    <font-awesome-icon icon="fa-solid fa-user-gear" />
                                    <ul>
                                        <li>
                                            <a>
                                                <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-circle-user" /></span>
                                                <span class="span_tit">{{ langweb('fe.user') }}: </span><span class="span_content">{{ nameuser }}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-hand-holding-dollar" /></span>
                                                <span class="span_tit">{{ langweb('fe.amount') }}: </span><span class="span_content">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(total_mn) }}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                            <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-money-bill-1-wave" /></span>
                                                <span class="span_tit">{{ langweb('fe.point') }}: </span><span class="span_content">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(point) }}</span>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a>
                                                <span class="icon_account_edit"><font-awesome-icon icon="fa-solid fa-money-bill" /></span>
                                                <span class="span_tit">{{ langweb('fe.bacara') }}: </span><span class="span_content">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(casino) }}</span>
                                            </a>
                                        </li> -->
                                        <!-- <li class="convert_mobile"><router-link class="nav-item" v-bind:to="{ name: 'PointToAmount_city' }">{{ langweb('fe.pointtoamount') }}</router-link></li>
                                        <li><router-link class="nav-item" v-bind:to="{ name: 'ChangeInfo_city' }">{{ langweb('fe.infomation') }}</router-link></li> -->
                                        <li><a data_id="5" @click="showMain">{{ langweb('fe.page') }}</a></li>
                                    </ul>
                                </li>
                                <li class="infomation_edit" data_id="5" @click="showMain"><font-awesome-icon icon="fa-solid fa-user-gear" />&nbsp; {{ langweb('fe.page') }}</li>
                                <li v-if="checktoken === true" @click="logout" class="logout_edit">
                                    <font-awesome-icon icon="fa-solid fa-right-from-bracket" />&nbsp;{{ langweb('fe.logouts') }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="bal-container ml-auto" v-if="checkform === true">
                    <form @submit="login" method="post">
                        <div class="before-login active">
                            <div class="desktop">
                                <div class="dflex-ac-jc">
                                    <div class="input-panel dflex-ac-jc">
                                        <div class="icon-panel dflex-ac-jc">
                                            <font-awesome-icon icon="fa-solid fa-user-tie" />
                                        </div>
                                        <input type="text" name="username_lg" v-model="username_lg" :placeholder="langweb('fe.username')" id="username_lg" autocomplete="off">
                                    </div>
                                    <div class="input-panel dflex-ac-jc">
                                        <div class="icon-panel dflex-ac-jc">
                                            <font-awesome-icon icon="fa-solid fa-unlock-alt" />
                                        </div>
                                        <input type="password" name="password_lg" v-model="password_lg" :placeholder="langweb('fe.passwords')" id="password_lg" autocomplete="off">
                                    </div>
                                    <button type="button" class="register_mobile btn-red join-link" @click="showModals = true">{{ langweb('fe.registers') }}</button>
                                    <button type="submit" class="btn-yellow login-btn">{{ langweb('fe.login') }}</button>
                                    <button type="button" class="register_desktop btn-red join-link" @click="showModals = true">{{ langweb('fe.registers') }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </header>
        <div id="modal-example" class="modal-vue">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal">
                    <div class="header-modal">
                        <div class="title-header">{{ langweb('fe.notification') }}</div>
                        <button class="close-modal" @click="showModal = false">x</button>
                    </div>
                    <div class="content-modal">
                        <div class="text-content" v-if="level == 1 && offline == 1">{{ langweb('fe.leveloffline') }}</div>
                        <div class="text-content" v-else>{{ langweb('fe.texts') }}</div>
                    </div>
                    <div class="footer-modal">
                        <button class="config-modal" @click="showModal = false">{{ langweb('fe.ok') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-example" class="modal-game">
            <div class="overlay" v-if="showModals" @click="showModals = false"></div>
            <div class="modal" v-if="showModals">
                <div class="wp-modal-register">
                    <div class="left-right-login">
                        <div class="left-login">
                            <img src="../assets/images/left.png" alt="">
                        </div>
                        <div class="form-login">
                            <button class="close-modal-game" @click="showModals = false"><img src="../assets/images/closes.svg" alt=""></button>
                            <form ref="form_register" @submit="register" method="POST" class="form_register_header" id="login-game">
                                <h2 class="tit-login">{{ langweb('fe.registers') }}</h2>
                                <p><span>*</span> {{ langweb('fe.request') }}</p>
                                <div class="form-group input-email">
                                    <label for="">{{ langweb('fe.referrial') }}</label>
                                    <input type="text" name="partner_code" v-model="partner_code" autocomplete="off" :placeholder="langweb('fe.referrial')" >
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.yournickname') }}</label>
                                    <input type="text" name="name" autocomplete="off" v-model="name" :placeholder="langweb('fe.yournickname') + ' *'" >
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.yourid') }}</label>
                                    <input type="text" name="username" autocomplete="off" v-model="username" :placeholder="langweb('fe.yourid') + ' *'" >
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.passwords') }}</label>
                                    <input type="password" name="password" autocomplete="off" v-model="password" :placeholder="langweb('fe.passwords') + ' *'" onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9!@#]/g, '')">
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.confirmpassword') }}</label>
                                    <input type="password" name="password_confirm" autocomplete="off" v-model="password_confirm" :placeholder="langweb('fe.confirmpassword') + ' *'" onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9!@#]/g, '')">
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.numbercash') }}</label>
                                    <input type="text" name="number_cash" autocomplete="off" v-model="number_cash" :placeholder="langweb('fe.numbercash') + ' *'" onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9가-힣]/g, '')">
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.phone') }}</label>
                                    <input type="number" name="phone" autocomplete="off" v-model="phone" maxlength="11" class="phonerg" :placeholder="langweb('fe.phone')" onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9가-힣]/g, '')">
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.namebank') }}</label>
                                    <input type="text" name="name_bank" autocomplete="off" v-model="name_bank" :placeholder="langweb('fe.namebank')" >
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.numberbank') }}</label>
                                    <input type="text" name="number_bank" autocomplete="off" v-model="number_bank" :placeholder="langweb('fe.numberbank')" >
                                </div>
                                <div class="form-group">
                                    <label for="">{{ langweb('fe.userbank') }}</label>
                                    <input type="text" name="user_bank" autocomplete="off" v-model="user_bank" :placeholder="langweb('fe.userbank')" >
                                </div>
                                <div class="btn-register btn_resgiter_header">
                                    <label for=""></label>
                                    <button class="btn-signin" type="submit">{{ langweb('fe.signup') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button id="pagetop" v-show="scY > 300" @click="toTop" class="scroll-top w-ba"><font-awesome-icon icon="fa-solid fa-caret-up" /></button> -->
        <div class="lang-web" style="display;">
            <a @click="clickEn"><img src="../assets/images/en.svg" alt=""></a>
            <a @click="clickKr"><img src="../assets/images/kr.svg" alt=""></a>
            <a @click="clickVn"><img src="../assets/images/vn.svg" alt=""></a>
        </div>
        <div :class="'root-popupshow ' + showpp + ' ' + cssnone" v-if="checkTk != null">
            <div class="container">
                <div class="row" style="justify-content:center;">
                    <div :class="'item_popup_show itemremove' + item.id" v-for="(item, index) in listpopup" v-bind:key="index" :style="'width:' + item.width + 'px;height:' + item.height + 'px'">
                        <div class="itempopup_child">
                            <p class="st_popup">{{ langweb('fe.notification') }}</p>
                            <div class="contentpp_edit">
                                <p class="titlepopup">{{ item.title }}</p>
                                <div class="csstext-popup" v-if="!readMore[index]" v-html="item.content.substring(0, 150)"></div>
                                <div class="csstext-popup" v-if="readMore[index]" v-html="item.content"></div>
                                <p @click="showMore(index)" v-if="!readMore[index] && item.content.length > 150" class="showmore">{{ langweb('fe.seemore')}}</p>
                                <p @click="showLess(index)" v-if="readMore[index]" class="lessmore">{{ langweb('fe.showless')}}</p>
                            </div>
                        </div>
                        <div class="btn_close_pp">
                            <button class="hidden_popup" @click="hidden_pp" :data_id="item.id">{{ langweb('fe.missing') }}</button>
                            <button class="delete_popup">{{ langweb('fe.close') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- cash in, list cash in, cash out, list cash out, notification, chat -->
        <div class="root-cash popupMain" v-if="checktoken === true">
            <div class="wraper-cash">
                <div class="cash-tab">
                    <div class="tabcash">
                        <div class="titmenu_popup">{{ langweb('fe.catmenu') }} <img src="../assets/images/right.svg" alt=""></div>
                        <ul>
                            <li class="tab1" data_tab="tab1" v-bind:class="{active: tabSelected == 'tab1'}" v-on:click="changeTab('tab1')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-piggy-bank" />&nbsp; {{ langweb('fe.cashin') }}
                                </a>
                            </li>
                            <!-- <li class="tab2" data_tab="tab2" v-bind:class="{active: tabSelected == 'tab2'}" v-on:click="changeTab('tab2')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-table-list" />&nbsp; {{ langweb('fe.paymentlist') }}
                                </a>
                            </li> -->
                            <li class="tab3" data_tab="tab3" v-bind:class="{active: tabSelected == 'tab3'}" v-on:click="changeTab('tab3')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-vault" />&nbsp; {{ langweb('fe.cashout') }}
                                </a>
                            </li>
                            <li class="tab4" data_tab="tab4" v-bind:class="{active: tabSelected == 'tab4'}" v-on:click="changeTab('tab4')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-table-list" />&nbsp; {{ langweb('fe.history') }} <!-- withdrawallist -->
                                </a>
                            </li>
                            <li class="tab8" data_tab="tab8" v-bind:class="{active: tabSelected == 'tab8'}" v-on:click="changeTab('tab8')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-money-bill-transfer" />&nbsp; {{ langweb('fe.pointtoamount') }}
                                </a>
                            </li>
                            <li class="tab5" data_tab="tab5" v-bind:class="{active: tabSelected == 'tab5'}" v-on:click="changeTab('tab5')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-bell" />&nbsp; {{ langweb('fe.notification') }}
                                </a>
                            </li>
                            <li class="tab6" data_tab="tab6" v-bind:class="{active: tabSelected == 'tab6'}" v-on:click="changeTab('tab6')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-comment-dots" />&nbsp; {{ langweb('fe.chat') }}
                                </a>
                            </li>
                            <li class="tab11" data_tab="tab11" v-bind:class="{active: tabSelected == 'tab11'}" v-on:click="changeTab('tab11')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-comments" />&nbsp; {{ langweb('fe.sends') }}
                                </a>
                            </li>
                            <li class="tab7" data_tab="tab7" v-bind:class="{active: tabSelected == 'tab7'}" v-on:click="changeTab('tab7')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-circle-info" />&nbsp; {{ langweb('fe.info') }}
                                </a>
                            </li>
                            <li class="tab9" data_tab="tab9" v-bind:class="{active: tabSelected == 'tab9'}" v-on:click="changeTab('tab9')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />&nbsp; {{ langweb('fe.betting') }}
                                </a>
                            </li>
                            <li class="tab10" data_tab="tab10" v-bind:class="{active: tabSelected == 'tab10'}" v-on:click="changeTab('tab10')">
                                <a>
                                    <font-awesome-icon icon="fa-solid fa-gift" />&nbsp; {{ langweb('fe.gift') }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="root-content-cash-tab">
                        <div class="content-tab-cashin">
                            <div class="tab1" v-if="tabSelected === 'tab1'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-piggy-bank" />&nbsp; {{ langweb('fe.cashin') }}
                                    </div>
                                </div>
                                <div class="bgr_cash">
                                    <font-awesome-icon icon="fa-solid fa-circle-info" />&nbsp; {{ langweb('fe.textcs') }}
                                    <p style="margin-bottom: 0;margin-top: 5px;">{{ langweb('fe.textcash') }}</p>
                                </div>
                                <div class="root_main_cash">
                                    <Cashin_city />
                                </div>
                            </div>
                            <div class="tab2" v-if="tabSelected === 'tab2'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-table-list" />&nbsp; {{ langweb('fe.paymentlist') }}
                                    </div>
                                </div>
                                <div class="root_main_cash" v-if="set==2">
                                    <ListCashin_city />
                                </div>
                            </div>
                            <div class="tab3" v-if="tabSelected === 'tab3'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-vault" />&nbsp; {{ langweb('fe.cashout') }}
                                    </div>
                                </div>
                                <div class="bgr_cash">
                                    <font-awesome-icon icon="fa-solid fa-circle-info" />&nbsp; {{ langweb('fe.textcashout') }}
                                </div>
                                <div class="root_main_cash">
                                    <Cashout_city @change_amount="change_amount_header" />
                                </div>
                            </div>
                            <div class="tab4" v-if="tabSelected === 'tab4'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-table-list" />&nbsp; {{ langweb('fe.withdrawallist') }}
                                    </div>
                                </div>
                                <div class="root_main_cash" v-if="set==4">
                                    <ListCashout_city />
                                </div>
                            </div>
                            <div class="tab5" v-if="tabSelected === 'tab5'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-bell" />&nbsp; {{ langweb('fe.notification') }}
                                    </div>
                                </div>
                                <div class="root_main_cash">
                                    <Notification_city />
                                </div>
                            </div>
                            <div class="tab6" v-if="tabSelected === 'tab6'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-comment-dots" />&nbsp; {{ langweb('fe.chat') }}
                                    </div>
                                </div>
                                <div class="root_main_cash">
                                    <Chat_city />
                                    <Contact_city />
                                </div>
                            </div>
                            <div class="tab11" v-if="tabSelected === 'tab11'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-comments" />&nbsp; {{ langweb('fe.sends') }}
                                    </div>
                                </div>
                                <div class="root_main_cash">
                                    <Send_city />
                                </div>
                            </div>
                            <div class="tab7" v-if="tabSelected === 'tab7'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-circle-info" />&nbsp; {{ langweb('fe.info') }}
                                    </div>
                                </div>
                                <div class="root_main_cash">
                                    <ChangeInfo_city />
                                </div>
                            </div>
                            <div class="tab8" v-if="tabSelected === 'tab8'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-money-bill-transfer" />&nbsp; {{ langweb('fe.pointtoamount') }}
                                    </div>
                                </div>
                                <div class="root_main_cash">
                                    <PointToAmount_city @change_point_amount="change_amount_header"/>
                                </div>
                            </div>
                            <div class="tab9" v-if="tabSelected === 'tab9'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />&nbsp; {{ langweb('fe.betting') }}
                                    </div>
                                </div>
                                <div class="root_main_cash" v-if="set==9">
                                    <Betting_city />
                                </div>
                            </div>
                            <div class="tab10" v-if="tabSelected === 'tab10'">
                                <div class="text_category">
                                    <div>
                                        <font-awesome-icon icon="fa-solid fa-gift" />&nbsp; {{ langweb('fe.gift') }}
                                    </div>
                                </div>
                                <div class="root_main_cash" v-if="set==10">
                                    <Gift_city />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="../assets/images/closes.svg" alt="" @click="closePopup" class="closetab">
                </div>
            </div>
        </div>
        <!-- end cash in, list cash in, cash out, list cash out, notification, chat -->
        <audio id="audio_send" v-if="audioSend==1" autoplay loop>
            <source src="../assets/images/sms_cash_in.wav" type="audio/mpeg">
        </audio>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Cashin_city from '@/components/Cashin'
import ListCashin_city from '@/components/ListCashIn'
import Cashout_city from '@/components/Cashout'
import ListCashout_city from '@/components/ListCashOut'
import Notification_city from '@/components/Notification'
import Chat_city from '@/components/ChatList'
import Contact_city from '@/components/SendChat'
import ChangeInfo_city from '@/components/ChangeInfo'
import PointToAmount_city from '@/components/PointToAmount'
import Betting_city from '@/components/Betting'
import Gift_city from '@/components/Gift'
import Send_city from '@/components/Send'

import { useStore } from "vuex";
import {  computed } from 'vue';
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on('click', '.delete_popup', function() {
        $(this).parent().parent().remove();
        let length = $('.root-popupshow .item_popup_show').length;
        if(length < 1){
            $('.root-popupshow').hide();
            $('.root-popupshow').removeClass('active');
        }
    });
});
export default {
    name: 'Header_city',
    setup() {
        const store = useStore();
        const lang = computed(() => store.state.lang);
        const lang_en = computed(() => store.state.language);
        store.dispatch("changLang");
        return {
            langweb (variable) {
                lang.value.filter(function(e){
                    if(e.variable == variable) {
                        if(lang_en.value == 'en'){
                            variable = e.en
                        }else if(lang_en.value == 'kr'){
                            variable = e.kr
                        }
                        else{
                            variable = e.vn
                        }
                    }
                });
                return variable;
            },
            clickEn () {
                store.commit('clickEn')
            },
            clickKr () {
                store.commit('clickKr')
            },
            clickVn () {
                store.commit('clickVn')
            },
        };
    },
    data () {
        return {
            showModal: false,
            showModals: false,
            showpopup: false,
            scTimer: 0,
            scY: 0,
            partner_code: '',
            name: '',
            username: '',
            password: '',
            password_confirm: '',
            number_cash: '',
            phone: '',
            name_bank: '',
            number_bank: '',
            user_bank: '',
            error: false,
            username_lg: '',
            password_lg: '',
            token: '',
            checktoken: false,
            checkform: true,
            amount: '',
            point: '',
            casino: '',
            result: '',
            nameuser: '',
            notifi: '',
            popup: '',
            chat: '',
            send: '',
            listpopup: [],
            readMore: [false],
            checkTk: window.localStorage.getItem('token'),
            showpp: '',
            cssnone: 'displaynone',
            tabSelected: 'tab1',
            set: 0,
            listSendPopup: [],
            id_lg: '',
            level: window.localStorage.getItem('level'),
            offline: window.localStorage.getItem('offline'),
            total_mn: '',
            listSend_header: [],
            audioSend: 0
        }
    },
    mounted () {
        // window.addEventListener('scroll', this.handleScroll)
        this.token = window.localStorage.getItem('token')
        if (this.token !== null) {
            this.checktoken = true
            this.checkform = false
        }
    },
    created () {
        this.token = window.localStorage.getItem('token')
        if(this.token !== null){
            this.getListInfo()
            this.getNumberinfo()
            this.getListPopup()
            //this.resettoken()
            setInterval(this.getListInfo, 10000);
            setInterval(this.getNumberinfo, 10000);
            this.getListSendpp()
            this.getListSend()
        }
    },
    components: {
        Cashin_city,
        ListCashin_city,
        Cashout_city,
        ListCashout_city,
        Notification_city,
        Chat_city,
        Contact_city,
        ChangeInfo_city,
        PointToAmount_city,
        Betting_city,
        Gift_city,
        Send_city,
    },
    methods: {
        // handleScroll () {
        //     if (this.scTimer) return
        //     this.scTimer = setTimeout(() => {
        //     this.scY = window.scrollY
        //     clearTimeout(this.scTimer)
        //     this.scTimer = 0
        //     }, 100)
        // },
        // toTop () {
        //     window.scrollTo({
        //         top: 0,
        //         behavior: "smooth"
        //     })
        // },
        register (e) {
            e.preventDefault()
            this.axios.post(this.api_register, {
                partner_code: this.partner_code,
                name: this.name,
                username: this.username,
                password: this.password,
                password_confirm: this.password_confirm,
                number_cash: this.number_cash,
                phone: this.phone,
                name_bank: this.name_bank,
                number_bank: this.number_bank,
                user_bank: this.user_bank
            }).then(res => {
                if (res.data.success === true) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.showModals = false
                    this.partner_code = this.name = this.username = this.password = this.password_confirm = this.number_cash = this.phone = this.name_bank = this.number_bank = this.user_bank = ''
                    e.target.reset()
                } else {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            }).catch(e => {
                if (e.response.status === 404) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        login (e) {
            e.preventDefault()
            this.axios.post(this.api_login, {
                username: this.username_lg,
                password: this.password_lg
            }).then(res => {
                if (res.data.success === true) {
                    window.localStorage.setItem('token', res.data.data.token)
                    window.localStorage.setItem('level', res.data.data.user.level)
                    window.localStorage.setItem('offline', res.data.data.user.offline)
                    let today = new Date();
                    let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                    let timestamp = date + ' ' + time;
                    window.localStorage.setItem('time_token', timestamp)
                    this.checkform = false
                    this.checktoken = true
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    this.username_lg = this.password_lg = ''
                    window.location.href = '/'
                } else {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            }).catch(e => {
                if (e.response.status === 404) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        logout (e) {
            e.preventDefault()
            this.axios.post(this.api_logout, 
                {
                    
                },
                {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then(res => {
                if (res.data.success === true) {
                    this.checktoken = false
                    this.checkform = true
                    localStorage.clear();
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    window.location.href = '/'
                }
            })
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.amount = response.data.data.user.amount
                this.point = response.data.data.user.point
                this.casino = response.data.data.user.casino
                this.nameuser = response.data.data.user.name
                this.total_mn = Number(this.amount) + Number(this.casino)
            }).catch(e => {
                if (e.response.status === 401) {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 11000
                    })
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                this.checktoken = false
                                this.checkform = true
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 10000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        click_login () {
            this.$swal({
                position: 'top-end',
                icon: 'error',
                title: '먼저 로그인해야 합니다',
                showConfirmButton: false,
                timer: 3000
            })
        },
        resettoken () {
            if(window.localStorage.getItem('token') !== null){
                let timetk = window.localStorage.getItem('time_token')
                let today = new Date();
                let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                let timestamp = date + ' ' + time;
                let timereset = Math.round(+new Date(timestamp)/1000) - Math.round(+new Date(timetk)/1000)
                let result = timereset / 60
                if(result > 60){
                    this.axios.post(this.api_logout).then(res => {
                        if (res.data.success === true) {
                            this.checktoken = false
                            this.checkform = true
                            localStorage.clear();
                            window.location.href = '/'
                        }
                    })
                    // this.axios.get(this.api_resettoken, {
                    //     headers: {
                    //         Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    //     }
                    // }).then((response) => {
                    //     window.localStorage.setItem('token', response.data.data)
                    //     let today = new Date();
                    //     let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
                    //     let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                    //     let timestamp = date + ' ' + time;
                    //     window.localStorage.setItem('time_token', timestamp)
                    // }).catch(e => {
                    //     if (e?.response?.status === 500) {
                    //         alert('세션이 종료되었습니다. 계속하려면 로그인하세요.')
                    //         localStorage.clear();
                    //         window.location.href = '/'
                    //     }
                    // })
                }
            }
        },
        getNumberinfo () {
            this.axios.get(this.api_number, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.notifi = response.data.data.notifi
                this.popup = response.data.data.popup
                this.chat = response.data.data.chat
                this.send = response.data.data.send
            })
        },
        setNumbernotifi () {
            this.notifi = '0'
        },
        setNumberpopup () {
            this.popup = '0'
            this.showpopup = true
            this.showpp = 'active'
        },
        setNumberchat () {
            this.chat = '0'
        },
        getListPopup () {
            var token_user = window.localStorage.getItem('token')
            this.axios.get(this.api_listpopup, {
                headers: {
                    Authorization: 'Bearer ' + token_user
                }
            }).then((response) => {
                response.data.data.list_data.forEach((item) => {
                    if( this.$cookies.get(item.id) !== 'close' ){
                        this.listpopup.push(item)
                    }
                    if(this.listpopup.length > 0){
                        this.cssnone = ''
                    }
                })
            })
        },
        showMore(index) {
            this.readMore[index] = true;
        },
        showLess(index) {
            this.readMore[index] = false;
        },
        changeTab (tab) {
            this.tabSelected = tab
            var datatab = $('.'+tab).attr('data_tab')
            if(datatab == 'tab1'){ this.set = 1 }
            else if(datatab == 'tab2'){ this.set = 2 }
            else if(datatab == 'tab3'){ this.set = 3 }
            else if(datatab == 'tab4'){ this.set = 4 }
            else if(datatab == 'tab5'){ this.set = 5 }
            else if(datatab == 'tab6'){ this.set = 6 }
            else if(datatab == 'tab7'){ this.set = 7 }
            else if(datatab == 'tab8'){ this.set = 8 }
            else if(datatab == 'tab9'){ this.set = 9 }
            else if(datatab == 'tab10'){ this.set = 10 }
            else{ this.set = 11 }
        },
        hidden_pp (event) {
            let idpp = event.target.getAttribute('data_id')
            this.$cookies.set(idpp, 'close', "1d");
            $('.itemremove'+idpp).remove();
            let length = $('.root-popupshow .item_popup_show').length;
            if(length < 1){
                $('.root-popupshow').hide();
                $('.root-popupshow').removeClass('active');
            }
        },
        showMain (event) {
            var id = Number(event.target.getAttribute('data_id'))
            if(id===1){
                this.tabSelected = 'tab1'
            }else if(id===2){
                this.tabSelected = 'tab3'
            }else if(id===3){
                this.notifi = '0'
                this.tabSelected = 'tab5'
            }else if(id===4){
                this.chat = '0'
                this.tabSelected = 'tab6'
            }else if(id===5){
                this.tabSelected = 'tab7'
            }else if(id===6){
                this.tabSelected = 'tab8'
            }else{
                this.tabSelected = 'tab11'
            }
            $('.popupMain').show();
            $('body').css('overflow', 'hidden')
        },
        closePopup () {
            $('.popupMain').hide();
            $('body').css('overflow', 'initial')
        },
        getListSendpp () {
            this.axios.get(this.api_listSend, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.listSendPopup = response.data.data.list_data
                this.id_lg = response.data.data.user_id
                // this.listSendPopup.forEach((value) => {
                //     let checkSend = Object.values(value.user_view.split(',').map((e) => parseInt(e))).includes(this.id_lg)
                //     if(!checkSend){
                //         this.tabSelected = 'tab11'
                //         $('.popupMain').show();
                //         $('body').css('overflow', 'hidden')
                //         return;
                //     }
                // });
                const array = this.listSendPopup
                let shouldExit = false;

                array.forEach(value => {
                    if (shouldExit) {
                        return; // Exit the loop
                    }
                    let checkSend = Object.values(value.user_view.split(',').map((e) => parseInt(e))).includes(this.id_lg)
                    if (!checkSend) {
                        $('.popupMain').show();
                        $('body').css('overflow', 'hidden')
                        this.tabSelected = 'tab11'
                        shouldExit = true; // Set the flag to exit
                        return;
                    }
                });
            })
        },
        showLevel () {
            this.showModal = true
        },
        getListSend () {
            this.axios.get(this.api_listSend, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.listSend_header = response.data.data.list_data
                this.id_lg = response.data.data.user_id
                const array = this.listSend_header
                let shouldExit = false;

                array.forEach(value => {
                    if (shouldExit) {
                        return; // Exit the loop
                    }
                    let checkSend = Object.values(value.user_view.split(',').map((e) => parseInt(e))).includes(this.id_lg)
                    // console.log(checkSend);
                    if (!checkSend) {
                        this.audioSend = 1
                        shouldExit = true; // Set the flag to exit
                        return;
                    }else{
                        this.audioSend = 0
                    }
                });
            })
        },
        change_amount_header () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.amount = response.data.data.user.amount
                this.point = response.data.data.user.point
                this.casino = response.data.data.user.casino
                this.nameuser = response.data.data.user.name
                this.total_mn = Number(this.amount) + Number(this.casino)
            })
        },
    }
}
</script>
<style>
</style>
