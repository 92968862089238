<template>
    <div v-if="mainHide == 1">
        <Header_city />
            <router-view :key ='$route.params'/>
        <Footer_city/>
    </div>
    <div class="bgr_maintenance" v-else>
        <div class="container maintenance">
            <img src="./assets/images/system.png" alt="">
            <h1 class="error-title"><span>We’ll be back soon!</span></h1>
            <div v-html="message" class="text_maintenance"></div>
        </div>
    </div>
</template>
<script>
import "./assets/css/style.css"
import Header_city from './components/Header.vue'
import Footer_city from './components/Footer.vue'

export default {
    name: 'App',
    data () {
        return {
            mainHide: 1,
            message: ''
        }
    },
    components: {
        Header_city,
        Footer_city
    },
    created () {
        this.mainTenance();
        setInterval(this.mainTenance, 5000);
    },
    methods: {
        mainTenance () {
            this.axios.get(this.api_Setting).then(res => {
                if (res.data.success === true) {
                    this.mainHide = 1
                } else {
                    this.mainHide = 1
                }
            }).catch(e => {
                this.message = e.response.data.message
                if(e.response.status == 503){
                    this.mainHide = 0
                }
            })
        },
    }
}
</script>

<style>
</style>
