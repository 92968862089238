<template>
    <div class="wraper-root">
        <main class="page-content w-ba">
            <section class="toggle-section">      
                 <!-- <div class="container dflex-ac-jc">
                    <button class="toggle-btn casino-toggle" v-bind:class="{active: tabSelected == 'tab1'}" v-on:click="changeTab('tab1')">
                        <div class="btn-panel dflex-ac-js w-ba ml-auto">
                            <div class="icon-panel w-ba dflex-ac-jc">
                                <img src="../assets/images/casino-icon.png">
                            </div>
                            <span class="category">{{ langweb('fe.livecasino') }} &nbsp;<font-awesome-icon icon="fa-solid fa-caret-down" class="icon-down" /></span>
                        </div>
                    </button>
                    <button class="toggle-btn slot-toggle" >
                        <div class="btn-panel dflex-ac-je w-ba mr-auto">
                            <span class="category"><font-awesome-icon icon="fa-solid fa-caret-down" class="icon-down" />&nbsp; {{ langweb('fe.slotgame') }}</span>
                            <div class="icon-panel w-ba dflex-ac-jc">
                                <img src="../assets/images/slot-icon.png">
                            </div>
                        </div>
                    </button>
                </div>  -->
                
                
                <div class="tab-content-toogle" >
                    <section class="game-provider">
                        <div class="container max-width-gib">

                            <div id="tab_img" >
                            <img src="../assets/images/casinobtn.png" v-if="token === null" @click="showModal = true">&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src="../assets/images/casinobtn.png" v-else v-on:click="changeTab('tab1')">&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src="../assets/images/slotbtn.png" >
                            </div>

                            <div class="tab1" v-if="tabSelected === 'tab1'" style="display:none;" id="tab1_show">
                                <div class="casino-section sc-section">
                                    <div class="dflex-ac-jc w-100">
                                        <a class="sc-btn w-ba" style="animation-delay: 0s;" v-for="(item, index) in listGameCasino" v-bind:key="index">
                                            <div class="g-panel w-ba">
                                                <div class="g-cont">
                                                    <img class="g-img" :src="'http://adm.channel01.com/' + item.image3" style="width: 315px;">
                                                    <button type="button" v-if="token === null" @click="showModal = true" class="play-btn btn-yellow">{{ langweb('fe.play') }}</button>
                                                    <button type="button" v-else-if="item.maintenance == 1" @click="getMaintenance()" class="play-btn btn-yellow">{{ langweb('fe.play') }}</button>
                                                    <button type="button" class="play-btn btn-yellows" v-else @click="getGameProcess(item.code,1)">{{ langweb('fe.play') }}</button>
                                                </div>    
                                                <!-- <div class="g-cont" v-else-if="item.type == 'slot'">
                                                    <img class="g-img" :src="'http://admin.funky25.com/' + item.image3" style="width: 315px;">
                                                    <button type="button" v-if="token === null" @click="showModal = true" class="play-btn btn-yellow">{{ langweb('fe.play') }}</button>
                                                    <button type="button" v-else-if="item.maintenance == 1" @click="getMaintenance()" class="play-btn btn-yellow">{{ langweb('fe.play') }}</button>
                                                    <button type="button" class="play-btn btn-yellows" :data_name="item.title" :data_id="item.code" v-else @click="showItemGame">{{ langweb('fe.play') }}</button>
                                                </div>   -->                                            
                                                <div class="g-info">
                                                    <span class="g-name">{{ item.title }}</span>
                                                </div>
                                                <div class="glass"></div>
                                            </div>
                                        </a>
                                    </div>

                                    
                                </div>
                            </div>
                            <div class="tab2" v-if="tabSelected === 'tab2'">
                                <div class="slot-section sc-section active">
                                    <div class="dflex-ac-jc w-100">
                                        <a class="sc-btn w-ba" style="animation-delay: 0s;" v-for="(item, index) in listGameSlot" v-bind:key="index">
                                            <div class="g-panel w-ba">
                                                <div class="g-cont">
                                                    <img class="g-img" :src="'http://adm.channel01.com/' + item.image3" style="width: 215px;">
                                                    <button type="button" v-if="token === null" @click="showModal = true" class="play-btn btn-yellow">{{ langweb('fe.play') }}</button>
                                                    <button type="button" v-else-if="item.maintenance == 1" @click="getMaintenance()" class="play-btn btn-yellow">{{ langweb('fe.play') }}</button>
                                                    <button type="button" class="play-btn btn-yellows" :data_name="item.title" :data_id="item.code" v-else @click="showItemGame">{{ langweb('fe.play') }}</button>
                                                </div>
                                                <div class="g-info">
                                                    <span class="g-name">{{ item.title }}</span>
                                                </div>
                                                <div class="glass"></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </section>
                </div>
            </section>
            <input type="hidden" name="datacode" class="datacode" value="0">
            <div id="loading_show" class="loading_show" style="position: fixed; left: 0; bottom: 0; top: 0%; right: 0%; text-align: center; background: rgba(0,0,0,.5); z-index: 999999999;">
                <div class="windows8"> 
                    <img src="../assets/images/loading.gif" alt="">
                </div>
            </div>
            <section class="board-section w-ba" v-if="token != null">
                <div class="container max-width-gib dflex-ac-jc">
                    <div class="board-panel notice">
                        <div class="header dflex-ac-jc w-b">
                            <div class="title-panel dflex-ac-jc mr-auto">
                                <div class="icon-panel">
                                    <img src="../assets/images/notice-icon.png" class="img_loahome">
                                </div>
                                <span>{{ langweb('fe.notification') }}</span>
                            </div>
                            <!-- <button class="more-btn notice-link dflex-ac-jc">더보기 111</button> -->
                        </div>
                        <div class="content w-b btm-notice-section">
                            <table class="w-100">
                                <tbody>
                                    <tr v-for="(item, index) in listnotifihome.slice(0,5)" v-bind:key="index">
                                        <td class="text-left">
                                            <a class="notifyhome_click" href="javascript:void(0);">{{ item.title.substring(0, 15)}}</a>
                                            <div class="substring_content_tbs hidepps" style="color: #fff;">
                                                <div class="flex_popup">
                                                    <div class="showtb">
                                                        <div class="height_scroll">
                                                            <p class="titlenoti">{{ item.title }}</p>
                                                            <div v-html="item.content" class="content_tb"></div>
                                                        </div>
                                                        <button class="close_notis" type="button"><img src="../assets/images/closes.svg" alt=""></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="date-td">
                                            {{ item.created_at.substring(0, 10)}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <!-- item game click slot -->
            <div class="root-cash popupItemGame" v-if="token !== null">
                <div class="wraper-cash">
                    <div class="cash-tab">
                        <div class="tabcash">
                            <div class="titmenu_popup">{{ langweb('fe.text1') }} <img src="../assets/images/right.svg" alt=""></div>
                            <ul>
                                <li>
                                    <form action="" method="" name="frm_search" id="_frm_search">
                                        <div class="input_search">
                                            <input type="text" v-model="search" class="key_search" placeholder="Search" @keyup="searchGame">
                                        </div>
                                    </form>
                                </li>
                                <li v-bind:class="{active: tabItemGame == 'taba'}" v-on:click="changeTabItemGame('taba')">
                                    <a>
                                        <font-awesome-icon icon="fa-solid fa-clover" />&nbsp; {{ langweb('fe.text3') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="root-content-cash-tab">
                            <div class="content-tab-cashin">
                                <div class="taba" v-if="tabItemGame === 'taba'">
                                    <div class="text_category">
                                        <div>
                                            <font-awesome-icon icon="fa-solid fa-clover" />&nbsp; <span class="namegaming"></span>
                                        </div>
                                    </div>
                                    <div class="root_main_cash dflex-ac-jc w-100 wrapper_tab_game">
                                        <a href="javascript:void(0);" @click="getGameProcess(item.code,2)" class="itemgamePP" v-for="(item, index) in datalt" v-bind:key="index">
                                            <div class="wp-itemgpp">
                                                <img class="g-img" :src="item.image">
                                                <div class="nameitem_game">
                                                    <span>{{ item.title }}</span>
                                                </div>
                                            </div>
                                        </a>
                                        <paginate class="container_paginater" @update:modelValue="clickCallbacklt" :totalCount="totalCountlt" :limit="perPagelt" v-model="pagelt"></paginate>
                                    </div>
                                </div>
                                <div class="tabb" v-if="tabItemGame === 'tabb'">
                                    <div class="text_category">
                                        <div>
                                            <font-awesome-icon icon="fa-solid fa-bomb" />&nbsp; {{ langweb('fe.text4') }}
                                        </div>
                                    </div>
                                    <div class="root_main_cash dflex-ac-jc w-100 wrapper_tab_game">
                                        {{ listGamingsearch }}
                                        <div class="card" v-for="(post, index) in listGamingsearch" :key="index">
                                            <a>
                                                {{ post.title }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src="../assets/images/closes.svg" alt="" @click="closePopupItemGame" class="closetab">
                    </div>
                </div>
            </div>
            <!-- end item game click slot -->
        </main>
        <div id="modal-example" class="modal-vue">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal">
                    <div class="header-modal">
                        <div class="title-header">{{ langweb('fe.notification') }}</div>
                        <button class="close-modal" @click="showModal = false">x</button>
                    </div>
                    <div class="content-modal">
                        <div class="text-content">{{ langweb('fe.texts') }}</div>
                    </div>
                    <div class="footer-modal">
                        <button class="config-modal" @click="showModal = false">{{ langweb('fe.ok') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal maintenance game -->
        <div id="modal-example" class="modal-vue">
            <div class="overlay" v-if="showModal_bt" @click="showModal_bt = false"></div>
            <div class="modal" v-if="showModal_bt">
                <div class="wp-modal">
                    <div class="header-modal">
                        <div class="title-header">{{ langweb('fe.notification') }}</div>
                        <button class="close-modal" @click="showModal_bt = false">x</button>
                    </div>
                    <div class="content-modal">
                        <div class="text-content">{{ langweb('fe.maintenance') }}</div>
                    </div>
                    <div class="footer-modal">
                        <button class="config-modal" @click="showModal_bt = false">{{ langweb('fe.ok') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End modal maintenance game -->
    </div>
</template>

<script>
import { useStore } from "vuex";
import {  computed } from 'vue';
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on('click', '.notifyhome_click', function() {
        $(this).parent().find('.substring_content_tbs').show();
        $('.toggle-section').css('z-index', '-1')
        $('.footer-section').css('z-index', '-1')
        $('body').css('overflow', 'hidden')
    });
    $(document).on('click', '.close_notis', function() {
        $(this).parents('.substring_content_tbs').hide();
        $('.toggle-section').css('z-index', '0')
        $('.footer-section').css('z-index', '0')
        $('body').css('overflow', 'initial')
    });
    $(document).on('click', '.notifyhome_clicks', function() {
        $(this).parent().find('.substring_content_tbss').show();
        $('.toggle-section').css('z-index', '-1')
        $('.footer-section').css('z-index', '-1')
        $('body').css('overflow', 'hidden')
    });
    $(document).on('click', '.close_notiss', function() {
        $(this).parents('.substring_content_tbss').hide();
        $('.toggle-section').css('z-index', '0')
        $('.footer-section').css('z-index', '0')
        $('body').css('overflow', 'initial')
    });
});
export default {
    name: 'Home_city',
    setup() {
        const store = useStore();
        const lang = computed(() => store.state.lang);
        const lang_en = computed(() => store.state.language);
        store.dispatch("changLang");
        return {
            langweb (variable) {
                lang.value.filter(function(e){
                    if(e.variable == variable) {
                        if(lang_en.value == 'en'){
                            variable = e.en
                        }else{
                            variable = e.kr
                        }
                    }
                });
                return variable;
            },
        }
    },
    data () {
        return {
            tabSelected: 'tab1',
            tabSelecteds: 'tab3',
            showModal: false,
            showModal_bt: false,
            listchat: [],
            token: window.localStorage.getItem('token'),
            listnotifihome: [],
            listGameCasino: [],
            listGameSlot: [],
            modalcoin: false,
            id_user: '1',
            title: '',
            question_content: '',
            listtitlehome: [],
            tabItemGame: 'taba',
            itemgamepp: [],
            pagelt: 1,
            perPagelt: 35,
            totalCountlt: 0,
            datalt: [],
            listSearch: [],
            search: '',
            listGaming: [],
            listGamingsearch: [],
            timeout_icon: null,
        }
    },
    components: {
    },
    created () {
        if(this.token !== null){
            this.getListnotifiHome()
        }
        this.getListGameCasino()
        this.getListGameSlot()
    },
    methods: {
        changeTab (tab) {
            this.tabSelected = tab

            $('#tab1_show').css('display', '')
            $('#tab_img').css('display', 'none')
            
        },
        changeTabs (tab) {
            this.tabSelecteds = tab
        },
        changeTabItemGame (tab) {
            this.tabItemGame = tab
        },
        getListnotifiHome () {
            var token_user = window.localStorage.getItem('token')
            this.axios.get(this.api_listnotifi, {
                headers: {
                    Authorization: 'Bearer ' + token_user
                }
            }).then((response) => {
                this.listnotifihome = response.data.data
            })
        },
        getListGameCasino () {
            this.axios.get(this.api_gamecasino).then((response) => {
                this.listGameCasino = response.data.data.filter(d => d.type === 'casino')
                // console.log(this.listGameCasino);
            })
        },
        getListGameSlot () {
            this.axios.get(this.api_gamecasino).then((response) => {
                // console.log(response.data.data);
                this.listGameSlot = response.data.data.filter(d => d.type === 'slot')
            })
        },
        // listTitle () {
        //     var token_user = window.localStorage.getItem('token')
        //     this.axios.get(this.api_listtitle, {
        //         headers: {
        //             Authorization: 'Bearer ' + token_user
        //         }
        //     }).then((response) => {
        //         this.listtitlehome = response.data.data.filter(d => d.type == 1)
        //         this.title = this.listtitlehome[0]?.id
        //     })
        // },
        getGameProcess (code,type) {
            $('.loading_show').show().fadeIn(10)
            var token_user = window.localStorage.getItem('token')
            this.axios.post(this.api_liveprocess, 
            {
                gamecode: code,
                gametype: type
            },
            { headers: {
                Authorization: 'Bearer ' + token_user
            }},
            ).then((response) => {

                if(response.data.data.result == 1)
                {
                    var size = {
                    width: window.innerWidth || document.body.clientWidth,
                    height: window.innerHeight || document.body.clientHeight
                    };
                    //창 크기 지정
                    var width = size.width;
                    var height = size.height;
                    //pc화면기준 가운데 정렬
                    var left = (window.screen.width / 2) - (width/2);
                    var top = (window.screen.height / 4);
                    //윈도우 속성 지정
                    var windowStatus = 'width='+width+', height='+height+', left='+left+', top='+top+', scrollbars=yes, status=yes, resizable=yes, titlebar=yes';
                    //연결하고싶은url
                    const url = response.data.data.url;
                    //등록된 url 및 window 속성 기준으로 팝업창을 연다.
                    window.open(url, "gamepopup", windowStatus);
                }
                else
                {
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: response.data.data.url,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
                
                $('#loading_show').css('display', 'none')
                
            })
        },
        showItemGame (event) {
            $('.loading_show').show().fadeIn(10)
            var dataid = (event.target.getAttribute('data_id'))
            var dataname = event.target.getAttribute('data_name')
            $('.datacode').val(dataid)
            $('.namegaming').text(dataname)
            let codeid = $('.datacode').val()
            $('.popupItemGame').show();
            $('.wraper-root').addClass('z_index_in')
            $('body').css('overflow', 'hidden')

            var token_user = window.localStorage.getItem('token')
            this.axios.get(this.api_listgameSlot + codeid, {
                headers: {
                    Authorization: 'Bearer ' + token_user
                }
            }).then((response) => {
                this.listGaming = response.data.data
                this.itemgamepp = response.data.data
                this.totalCountlt = this.itemgamepp.length
                this.paginatedDatalt()
                $('#loading_show').css('display', 'none')
            })
        },
        paginatedDatalt () {
            // console.log('this.itemgamepp',this.itemgamepp);
            this.datalt = this.itemgamepp.slice((this.pagelt - 1) * this.perPagelt, this.pagelt * this.perPagelt)
        },
        clickCallbacklt (pageNum) {
            this.pagelt = pageNum;
            this.paginatedDatalt();
        },
        closePopupItemGame () {
            $('.popupItemGame').hide();
            $('.wraper-root').removeClass('z_index_in')
            $('body').css('overflow', 'initial')
            this.datalt = []
        },
        searchGame (e) {
            e.preventDefault()
            let keyname = $('.key_search').val()
            let act_length = this.listGaming.length
            let dataArray = []
            let set = 0
            $.each(this.listGaming, function(i) {
                if (i+1 == act_length) {
                    set = 1;
                }
                if ( this.title.indexOf(keyname) != -1 || this.code.indexOf(keyname) != -1 ) {
                    return dataArray.push(this);
                }
                if (set == 1) {
                    // console.log('action: ', dataArray);
                    // return dataArray
                    // this.listGamingsearch = dataArray
                    // this.totalCountlt = this.itemgamepp.length
                    // this.paginatedDatalt()
                    // this.datalt = dataArray
                    // console.log('action: ', this.listGamingsearch);
                }
            });
            this.itemgamepp = dataArray
            this.totalCountlt = this.itemgamepp.length
            this.paginatedDatalt()
            // this.listGamingsearch = dataArray
            // console.log('dataArray',dataArray);
        },
        getMaintenance () {
            this.showModal_bt = true
        }
    }
}
</script>
<style>
</style>
